import Group115 from "assets/images/Group-115.png";
import Group116 from "assets/images/Group-116.png";
import Group118 from "assets/images/Group-118.png";
import Group119 from "assets/images/Group-119.png";
import Group132 from "assets/images/Group-132.png";
import Group133 from "assets/images/Group-133.png";
import Group134 from "assets/images/Group-134.png";
import Group135 from "assets/images/Group-135.png";

export const TrainingData = [
  {
    title:
      "CILT accredited Transport Manager Certificate of Professional Competence",
    heading:
      "The Certificate of Professional Competence (CPC) for Transport Managers in Road Haulage is offered by CILT(UK) on behalf of the Department for Transport and includes a range of subjects related to the business of road transport.",
    price: "£1495",
    days: "9",
    route:"cilt-accredited-transport-manager",
    icon:Group132,
    img:Group115,
    url:"cilt-accredited-transport-manager",
    schedule_url: "/cilt-accredited-transport-manager-cpc-course",

  },
  {
    title: "Transport Manager CPC Refresher Training",
    heading:
      "Our 2 days refresher course ensures that Transport Manager are following industry best practice, remain compliant with their Operator Licence undertakings and maintain a healthy Operator Compliance Risk Score (OCRS).",
    price: "£350",
    days: "2",
    route:"transport-manager-cpc-refresher",
    icon:Group133,
    img:Group116,
    url:"transport-manager-cpc-refresher",
  },
  {
    title: "Operator Licence Awareness Training",
    heading:
      "This one-day course will provide up-to-date knowledge in transport legislation as well as the latest guidance from Traffic Commissioners on compliance.",
    price: "£250",
    days: "1",
    route:"operator-licence-awareness",
    icon:Group134,
    img:Group118,
    url:"operator-licence-awareness",

  },
  {
    title: "IRTEC Accredited HGV Inspection Technician Training",
    heading:
      "The Certificate of Professional Competence (CPC) for Transport Managers in Road Haulage is offered by CILT(UK) on behalf of the Department for Transport and includes a range of subjects related to the business of road transport.",
    price: "£1495",
    days: "2",
    route:"irtec-accredited-hgv-inspection",
    icon:Group132,
    img:Group119,
    url:"irtec-accredited-hgv-inspection",
  },
  {
    title: "Periodic Driver CPC Training",
    heading:
      "Required by drivers who passed their practical driving test before September 2009 (HGV) or 2008 (PCV), or who have previously held a CPC entitlement for the relevant driving licence category.",
    price: "£60",
    days: "1",
    route:"periodic-driver-cpc",
    icon:Group135,
    img:Group115,
    url:"periodic-driver-cpc",
  },
  
  // {
  //   test:"Schedule"
  // }
];
// export const Test1 = [
//   {
   
//     title:
//       "CILT accredited Transport Manager Certificate of Professional Competence",
//     heading:
//       "The Certificate of Professional Competence (CPC) for Transport Managers in Road Haulage is offered by CILT(UK) on behalf of the Department for Transport and includes a range of subjects related to the business of road transport.",
//     price: "£1495",
//     days: "9",
//     route:"cilt-accredited-transport-manager-certificate-of-professional-competence"
//   }
// ];
