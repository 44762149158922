export const CiltatmCpcCourseScheduleinhtml = ` 
<html>
<head>
	<style>

		.desktop td, th {
			border: 1px solid #dddddd;
    padding: 0px 5px !important;

		}
		.desktop {
			width: 100%px;
			height: auto;
			border: 1px solid black;
			// overflow-x: scroll !important;
			// overflow-y: scroll !important;
		}
		.desktop ul{
      		padding: 0 0 0px 2em;
    	} 
          /* Schedule.css */

/* Hide the desktop content on mobile devices */
.desktop {
  display: block; /* Default display */
}

.Mobile {
  display: none; /* Default hidden */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .desktop {
    display: none; /* Hide on mobile */
  }
  .Mobile {
    display: block; /* Show on mobile */
  }
}


.Mobile td, th {
  border: 1px solid #dddddd;
  padding: 5px 5px !important;

}
.Mobile table {
  width: 1500px;
  height: auto;
  border: 1px solid black;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}
.Mobile .container {
  width: 360px;
  background-color: white;
}

.Mobile .hack2312 .mobile {
  width: 1148px !important;
  border-collapse: collapse;
}

.Mobile .hack2312 .mobile td {
  border: 1px solid black;
  style="width: 150px;
}

/* try removing the "hack" below to see how the table overflows the .body */
.Mobile .hack1 {
display: table;
table-layout: fixed;
width: 100%;
}

.Mobile .hack2312 {
display: table-cell;
overflow-x: auto;
width: 100%;
}
.Mobile .hack2312 .mobile1 ul{
      padding: 0 0 0px 2em;
  } 

	</style>
</head>
<body>

	<table class="desktop">
		<tr  style="background-color: #67d3d1 !important; color: #fff;     height: 50px;">
			<th style="color: #fff; width: 162px;">Date</th>
			<th style="color: #fff; width: 70px;" >Day</th>
			<th style="color: #fff; width: 760px;">Details</th>
			<th style="color: #fff; width: 326px;">Google Meet links</th>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>20th October 2024<br>Sunday</td>
			<td>Day 1</td>
			<td>
				<ul>
					<li>Introduction to Course and Framework of Regulations</li>
					<li>Business & Company Law</li>
					<li>Industrial Relations</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/uph-dupg-dnr" style="color: #67d3d1;">https://meet.google.com/uph-dupg-dnr</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>26th October 2024<br>Saturday</td>
			<td>Day 2</td>
			<td>
				<ul>
					<li>Health and Safety</li>
					<li>Quality standards and management</li>
					<li>Intro to practical Transport Management and keeping up your competency– statutory documentation and other guidance in more detail</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/xjh-cjkj-gmw" style="color: #67d3d1;">https://meet.google.com/xjh-cjkj-gmw</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>27th October 2024<br>Sunday</td>
			<td>Day 3</td>
			<td>
				<ul>
					<li>Financial Management + Budgeting</li>
					<li>Commercial Conduct</li>
					<li>Marketing</li>
					<li>Organisation Charts, KPls & Work Plans</li>
					<li>Progress Test</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/tbh-mwxt-pgx" style="color: #67d3d1;">https://meet.google.com/tbh-mwxt-pgx</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>2nd November 2024<br>Saturday</td>
			<td>Day 4</td>
			<td>
				<ul>
					<li>Vehicle Selection</li>
					<li>Operator Licensing</li>
					<li>VED and taxation</li>
					<li>Transport Ancillaries</li>
					<li>Examples and discussion</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/wwb-ahfv-ibe" style="color: #67d3d1;">https://meet.google.com/wwb-ahfv-ibe</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>3rd November 2024<br>Sunday</td>
			<td>Day 5</td>
			<td>
				<ul>
					<li>Vehicle Costing</li>
					<li>Maintenance Planning</li>
					<li>Maintenance records keeping</li>
					<li>Weights & Dimensions of Vehicles & Loads </li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/fyp-fcqr-dgh" style="color: #67d3d1;">https://meet.google.com/fyp-fcqr-dgh</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>16th November 2024<br>Saturday</td>
			<td>Day 6</td>
			<td>
				<ul>
					<li>Drivers Hours</li>
					<li>Working Time Regs</li>
					<li>Drivers Records + Attestations, Record books</li>
					<li>Recap of materials covered</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/jtk-vupf-iuz" style="color: #67d3d1;">https://meet.google.com/jtk-vupf-iuz</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>17th November 2024<br>Sunday</td>
			<td>Day 7</td>
			<td>
				<ul>
					<li>Safe Loading & Transit of Goods</li>
					<li>Driver Licensing</li>
					<li>Electronic Data</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/svk-hnev-jyx" style="color: #67d3d1;">https://meet.google.com/svk-hnev-jyx</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>23rd November 2024<br>Saturday</td>
			<td>Day 8</td>
			<td>
				<ul>
					<li>Insurance + CMR</li>
					<li>Plating & Testing</li>
					<li>Fleet Inspection & Roadside Checks</li>
					<li>Speed Limits & Traffic Regulations</li>
					<li>Traffic Accident Procedure UK & International</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/ihy-odou-uwu" style="color: #67d3d1;">https://meet.google.com/ihy-odou-uwu</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>24th November 2024<br>Sunday</td>
			<td>Day 9</td>
			<td>
				<ul>
					<li>Customs & Transit Systems</li>
					<li>Permits & Methods of Operating</li>
					<li>Business Taxation + International VAT content</li>
					<li>INCOTERMS and Frontier Crossings</li>
					<li>International Transport Documentation</li>
					<li>Revision of Financial Management Calculations</li>
					<li>Paper 1 Mock Exam</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/mho-byhk-xyx" style="color: #67d3d1;">https://meet.google.com/mho-byhk-xyx</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>30th Nov 2024**<br>Saturday</td>
			<td>Day 10</td>
			<td>
				<ul>
					<li>Revision** - Exam Structure</li>
					<li>Revision & Sample paper 1</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/veq-drud-ypm" style="color: #67d3d1;">https://meet.google.com/veq-drud-ypm</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>1st Dec 2024**<br>Sunday</td>
			<td>Day 11</td>
			<td>
				<ul>
					<li>Revision**</li>
					<li>Revision & Sample paper 2</li>
					<li>Exam Preparation & Course Close</li>
				</ul>
			</td>
			<td><a href="https://meet.google.com/ejy-kucw-gma" style="color: #67d3d1;">https://meet.google.com/ejy-kucw-gma</a> </td>
		</tr>
		<tr style="background-color: #fff !important;">
			<td>03rd Dec 2024</td>
			<td>Exam</td>
			<td>
				<ul>
					<li>Final Examinations part 1 and 2 (2 exams)</li>
				</ul>
			</td>
      <td></td>
		</tr>
	</table>

			</div>
		</div>
	</div>
</div>
</body>
</html>





`;
export const CiltatmCpcCourseScheduleinhtmlMobile = ` 
<html>
<head>
	<style>

		.desktop td, th {
			border: 1px solid #dddddd;
    padding: 0px 5px !important;

		}
		.desktop {
			width: 100%px;
			height: auto;
			border: 1px solid black;
		}
		.desktop ul{
      		padding: 0 0 0px 2em;
    	} 
          /* Schedule.css */

/* Hide the desktop content on mobile devices */
.desktop {
  display: block; /* Default display */
}

.Mobile {
  display: none; /* Default hidden */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .desktop {
    display: none; /* Hide on mobile */
  }
  .Mobile {
    display: block; /* Show on mobile */
  }
}


.Mobile td, th {
  border: 1px solid #dddddd;
  padding: 5px 5px !important;

}
.Mobile table {
  width: 1500px;
  height: auto;
  border: 1px solid black;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}
.Mobile .container {
  width: 360px;
  background-color: white;
}

.Mobile .hack2312 .mobile {
  width: 1148px !important;
  border-collapse: collapse;
}

.Mobile .hack2312 .mobile td {
  border: 1px solid black;
  style="width: 150px;
}

/* try removing the "hack" below to see how the table overflows the .body */
.Mobile .hack1 {
display: table;
table-layout: fixed;
width: 100%;
}

.Mobile .hack2312 {
display: table-cell;
overflow-x: auto;
width: 100%;
}
.Mobile .hack2312 .mobile1 ul{
      padding: 0 0 0px 2em;
  } 

	</style>
</head>

    <div class="Mobile" style=" padding: 0px; "> 
	<div class="container" style=" padding-top: 20px; ">
		<div class="hack1">
			<div class="hack2312">
				<table class="mobile1">
					<tr  style="background-color: #67d3d1; color: #fff;">
						<th style="color: #fff; width: 150px;">Date</th>
						<th style="color: #fff; width: 70px;" >Day</th>
						<th style="color: #fff; width: 250px;">Details</th>
						<th style="color: #fff; width: 200px;">Google Meet links</th>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>20th October 2024<br>Sunday</td>
						<td>Day 1</td>
						<td>
							<ul>
								<li>Introduction to Course and Framework of Regulations</li>
								<li>Business & Company Law</li>
								<li>Industrial Relations</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/uph-dupg-dnr" style="color: #67d3d1;">https://meet.google.com/uph-dupg-dnr</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>26th October 2024<br>Saturday</td>
						<td>Day 2</td>
						<td>
							<ul>
								<li>Health and Safety</li>
								<li>Quality standards and management</li>
								<li>Intro to practical Transport Management and keeping up your competency– statutory documentation and other guidance in more detail</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/xjh-cjkj-gmw" style="color: #67d3d1;">https://meet.google.com/xjh-cjkj-gmw</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>27th October 2024<br>Sunday</td>
						<td>Day 3</td>
						<td>
							<ul>
								<li>Financial Management + Budgeting</li>
								<li>Commercial Conduct</li>
								<li>Marketing</li>
								<li>Organisation Charts, KPls & Work Plans</li>
								<li>Progress Test</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/tbh-mwxt-pgx" style="color: #67d3d1;">https://meet.google.com/tbh-mwxt-pgx</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>2nd November 2024<br>Saturday</td>
						<td>Day 4</td>
						<td>
							<ul>
								<li>Vehicle Selection</li>
								<li>Operator Licensing</li>
								<li>VED and taxation</li>
								<li>Transport Ancillaries</li>
								<li>Examples and discussion</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/wwb-ahfv-ibe" style="color: #67d3d1;">https://meet.google.com/wwb-ahfv-ibe</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>3rd November 2024<br>Sunday</td>
						<td>Day 5</td>
						<td>
							<ul>
								<li>Vehicle Costing</li>
								<li>Maintenance Planning</li>
								<li>Maintenance records keeping</li>
								<li>Weights & Dimensions of Vehicles & Loads </li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/fyp-fcqr-dgh" style="color: #67d3d1;">https://meet.google.com/fyp-fcqr-dgh</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>16th November 2024<br>Saturday</td>
						<td>Day 6</td>
						<td>
							<ul>
								<li>Drivers Hours</li>
								<li>Working Time Regs</li>
								<li>Drivers Records + Attestations, Record books</li>
								<li>Recap of materials covered</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/jtk-vupf-iuz" style="color: #67d3d1;">https://meet.google.com/jtk-vupf-iuz</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>17th November 2024<br>Sunday</td>
						<td>Day 7</td>
						<td>
							<ul>
								<li>Safe Loading & Transit of Goods</li>
								<li>Driver Licensing</li>
								<li>Electronic Data</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/svk-hnev-jyx" style="color: #67d3d1;">https://meet.google.com/svk-hnev-jyx</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>23rd November 2024<br>Saturday</td>
						<td>Day 8</td>
						<td>
							<ul>
								<li>Insurance + CMR</li>
								<li>Plating & Testing</li>
								<li>Fleet Inspection & Roadside Checks</li>
								<li>Speed Limits & Traffic Regulations</li>
								<li>Traffic Accident Procedure UK & International</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/ihy-odou-uwu" style="color: #67d3d1;">https://meet.google.com/ihy-odou-uwu</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>24th November 2024<br>Sunday</td>
						<td>Day 9</td>
						<td>
							<ul>
								<li>Customs & Transit Systems</li>
								<li>Permits & Methods of Operating</li>
								<li>Business Taxation + International VAT content</li>
								<li>INCOTERMS and Frontier Crossings</li>
								<li>International Transport Documentation</li>
								<li>Revision of Financial Management Calculations</li>
								<li>Paper 1 Mock Exam</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/mho-byhk-xyx" style="color: #67d3d1;">https://meet.google.com/mho-byhk-xyx</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>30th Nov 2024**<br>Saturday</td>
						<td>Day 10</td>
						<td>
							<ul>
								<li>Revision** - Exam Structure</li>
								<li>Revision & Sample paper 1</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/veq-drud-ypm" style="color: #67d3d1;">https://meet.google.com/veq-drud-ypm</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>1st Dec 2024**<br>Sunday</td>
						<td>Day 11</td>
						<td>
							<ul>
								<li>Revision**</li>
								<li>Revision & Sample paper 2</li>
								<li>Exam Preparation & Course Close</li>
							</ul>
						</td>
						<td><a href="https://meet.google.com/ejy-kucw-gma" style="color: #67d3d1;">https://meet.google.com/ejy-kucw-gma</a> </td>
					</tr>
					<tr style="background-color: #fff !important;">
						<td>03rd Dec 2024</td>
						<td>Exam</td>
						<td>
							<ul>
								<li>Final Examinations part 1 and 2 (2 exams)</li>
							</ul>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</div>
</body>
</html>





`;